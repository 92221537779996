<template>
  <div class="d-flex flex-column h-100">
    <div
      class="d-flex p-1 content-header w-100 justify-content-between"
      style="border-bottom: 1px solid #e2e2e2; padding-top: 0.5rem"
    >
      <h2 class="m-0" style="padding-top: 5px">Informations</h2>
      <div class="d-flex my-0 w-100">
        <div class="d-flex w-100 py-0">
          <div class="d-flex justify-content-end w-100">
            <b-button @click="create" variant="primary">Enregistrer</b-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isLoadingUser || isCreatingUser || isUpdatingUser"
      class="content-loader-center m-0"
    >
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement des détails de l'utilisateur...
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column m-0 h-100 justify-content-center"
      style="height: calc(100% - 61px); overflow-y: auto"
    >
      <div class="d-flex p-1 w-100 flex-column justify-content-between h-100">
        <validation-observer ref="formCreateUser">
          <b-form autocomplete="off">
            <div id="general" class="my-0 d-flex flex-column w-100">
              <div class="d-flex w-100 pb-1">
                <div class="d-flex w-100">
                  <div class="d-flex w-100">
                    <b-form-group
                      label="Civilité"
                      label-for="civility"
                      class="w-50 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Civility"
                      >
                        <v-select
                          id="civility"
                          :state="errors.length == 0 ? false : null"
                          :reduce="(type) => type.value"
                          v-model="userForm.civility"
                          :options="optionCivility"
                        ></v-select>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="d-flex w-100 pb-1">
                <div class="d-flex w-100">
                  <div class="d-flex w-100">
                    <b-form-group
                      label="Nom *"
                      label-for="lastName"
                      class="w-100 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="lastName"
                        rules="required"
                      >
                        <b-form-input
                          id="lastName"
                          v-model="userForm.lastName"
                          :state="errors.length == 0 ? false : null"
                        ></b-form-input
                        ><small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="d-flex w-100">
                    <b-form-group
                      label="Prénom *"
                      label-for="firstName"
                      class="w-100 pl-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="firstName"
                        rules="required"
                      >
                        <b-form-input
                          id="firstName"
                          v-model="userForm.firstName"
                          :state="errors.length == 0 ? false : null"
                        ></b-form-input
                        ><small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="d-flex w-100 pb-1">
                <div class="d-flex w-100">
                  <div class="d-flex w-50">
                    <b-form-group
                      label="Email"
                      label-for="email"
                      class="w-100 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="userForm.email"
                          :state="errors.length == 0 ? false : null"
                        ></b-form-input
                        ><small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="d-flex w-50">
                    <b-form-group
                      label="Téléphone"
                      label-for="phoneNumber"
                      class="w-100 pl-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="phoneNumber"
                      >
                        <b-form-input
                          id="phoneNumber"
                          v-model="userForm.phoneNumber"
                          :state="errors.length == 0 ? false : null"
                        ></b-form-input>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div
                class="d-flex w-100 pb-1"
                v-if="userForm.id == '' || userForm.id == 0"
              >
                <div class="d-flex w-100">
                  <div class="d-flex w-50">
                    <b-form-group
                      label="Mot de passe *"
                      label-for="password"
                      class="w-100 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="password"
                        :rules="{
                          required: true,
                          min: 8,
                          regex:
                            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                        }"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="password"
                            v-model="userForm.password"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            name="password"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger" v-show="errors[0]"
                          >Doit contenir au moins 8 caractères dont : 1
                          majuscule, 1 minuscule, 1 chiffre, et 1 caractère
                          spécial</small
                        >
                        <!-- <b-form-input id="password" v-model="userForm.password" :state="errors.length &gt; 0 ? false : null"></b-form-input><small class="text-danger">{{ errors[0] }}</small> -->
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="d-flex w-50">
                    <b-form-group
                      label="Confirmer votre mot de passe *"
                      label-for="confirmPassword"
                      class="w-100 pl-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="confirmPassword"
                        :rules="'confirmed:password'"
                        data-vv-as="password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="confirmPassword"
                            v-model="userForm.confirmPassword"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            name="confirmPassword"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger" v-show="errors[0]"
                          >Confirmation invalide</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <b-col cols="12">
                <h4 class="mb-2">Environnements affectés</h4>
                <div
                  class="d-flex align-items-end"
                  :key="index"
                  v-for="(element, index) of userForm.workspaces"
                >
                  <div class="ml-1">
                    <div class="my-0">
                      <div class="d-flex py-0 mb-1 w-100">
                        <b-form-checkbox
                          class="mt-50 cursor-pointer"
                          v-model="element.isDefault"
                          inline
                          @change="
                            changeDefautWorkspace(index, element.isDefault)
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div style="flex: 1">
                    <div class="d-flex my-0">
                      <div class="py-0 mb-1 d-flex flex-column w-100">
                        <label
                          class="w-100"
                          label-for="workspaceId"
                          v-if="index == 0"
                          >Environnement *</label
                        >
                        <v-select
                          class="w-100"
                          :loading="isLoadingWorkspacesList"
                          :clearable="false"
                          :disabled="element.workspaceId > 0"
                          :deselectFromDropdown="true"
                          :closeOnSelect="true"
                          v-model="element.workspaceId"
                          label="socialReason"
                          :options="
                            element.workspace
                              ? [
                                  {
                                    socialReason:
                                      element.workspace.socialReason,
                                    id: element.workspace.id,
                                  },
                                ]
                              : element.options
                          "
                          :reduce="(elem) => elem.id"
                          ><template v-slot:no-options=""
                            ><template>
                              Aucun r&eacute;sultat trouv&eacute;</template
                            ></template
                          ></v-select
                        >
                      </div>
                    </div>
                  </div>
                  <div class="ml-1">
                    <div class="my-0 d-flex flex-column">
                      <b-col class="py-0 mb-1" cols="12">
                        <b-button
                          variant="outline-danger"
                          @click="deleteWorkspace(index, element)"
                        >
                          <feather-icon class="m-0" icon="TrashIcon" />
                        </b-button>
                      </b-col>
                    </div>
                  </div>
                </div>
                <div
                  class="mb-1"
                  @click="addWorkspaceForm"
                  v-show="
                    userForm.workspaces &&
                    userForm.workspaces.length > 0 &&
                    userForm.workspaces.find((elem) => elem.workspaceId == null)
                      ? false
                      : true
                  "
                >
                  <small class="cursor-pointer text-primary"
                    >+ Ajouter un environnement</small
                  >
                </div>
              </b-col>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash";
import Autocomplete from "vuejs-auto-complete";
import vSelect from "vue-select";
import { ECivility } from "@/types/api-orisis/enums/enums";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { civilityTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
      regex:
        "Doit contenir au moins : 1 majuscule, 1 minuscule, 1 chiffre, et 1 caractère spécial.",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  props: {
    userId: {
      default: null,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      scrolled: false,
      autocompleteUserName: "",
      autocompleteUserNameData: [],
      autocompleteAddressData: [],
      autocompleteAddressIndex: 0,
      optionCivility: [
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },
        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
      required,
      url,
      email,
      dataOrigine: {},
      userForm: {},
    };
  },
  async created() {
    this.getWorkspaces({});
  },
  mounted() {
    this.userForm = {
      id: this.userId,
      civility: this.user ? this.user.civility : "",
      firstName: this.user ? this.user.firstName : "",
      lastName: this.user ? this.user.lastName : "",
      email: this.user ? this.user.email : "",
      phoneNumber: this.user ? this.user.phoneNumber : "",
      isActive: this.user ? this.user.isActive : true,
      workspaces: this.user ? this.user.workspaces : [],
    };
    window.addEventListener("scroll", this.onScroll, true);
    this.deteleAutocomplete();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    civilityTranslate,
    ...mapActions(["createUser", "updateUser", "archiveUser", "getWorkspaces"]),
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    deteleAutocomplete() {
      let elements = document.querySelectorAll(".not-autocomplete input");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    create(e) {
      e.preventDefault();
      this.$refs.formCreateUser
        .validate()
        .then((success1) => {
          if (success1) {
            let user = {
              id: this.userForm.id,
              phoneNumber: this.userForm.phoneNumber,
              civility: this.userForm.civility,
              firstName: this.userForm.firstName,
              lastName: this.userForm.lastName,
              email: this.userForm.email,
              workspaceIds: this.userForm.workspaces.reduce((acc, item) => {
                acc[item.workspaceId] = item.isDefault;
                return acc;
              }, {}),
            };
            if (user.id) {
              this.updateUser({ user: user });
            } else {
              user.password = this.userForm.password;
              user.confirmPassword = this.userForm.confirmPassword;
              this.createUser({
                user: user,
              });
            }

            this.$nextTick(() => {
              this.$tabs.close({ to: "/users" });
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    changeDefautWorkspace(number, element) {
      for (let index = 0; index < this.userForm.workspaces.length; index++) {
        if (index !== number) {
          this.userForm.workspaces[index].isDefault = false;
        } else {
          this.userForm.workspaces[index].isDefault = true;
        }
      }
    },
    addWorkspaceForm() {
      let isDefault = true;
      if (this.userForm.workspaces.length > 0) {
        if (this.userForm.workspaces.find((elem) => elem.isDefault == true)) {
          isDefault = false;
        }
      }
      let workspaces = [];
      if (this.userForm.workspaces && this.userForm.workspaces.length > 0) {
        let workspacesListFiltered = [];
        for (let index = 0; index < this.workspacesList.length; index++) {
          const element = this.workspacesList[index];
          if (
            !this.userForm.workspaces.find((el) => el.workspaceId == element.id)
          ) {
            workspacesListFiltered.push(element);
          }
        }
        workspaces = workspacesListFiltered.map((elem) => {
          return {
            socialReason: elem.socialReason,
            id: elem.id,
          };
        });
      } else {
        workspaces = this.workspacesList;
      }
      this.userForm.workspaces.push({
        workspaceId: null,
        isDefault: isDefault,
        options: workspaces,
      });
    },
    async deleteWorkspace(index, element) {
      this.userForm.workspaces.splice(index, 1);
      if (element.isDefault == true && this.userForm.workspaces.length > 0) {
        this.userForm.workspaces[0].isDefault = true;
      }
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.userForm)) {
        this.$tabs.close();
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$tabs.close();
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters([
      "user",
      "isLoadingUser",
      "usersList",
      "isLoadingWorkspacesList",
      "workspacesList",
      "isCreatingUser",
      "isUpdatingUser",
    ]),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
  },
  directives: {
    Ripple,
    mask,
  },
};
</script>
<style lang="scss">
.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
