var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column h-100 py-0"},[_c('HeaderGrid',{attrs:{"title":"Paramètres","uid":_vm.uid,"addButton":_vm.addButton,"searchButton":_vm.searchButton,"selectedRows":_vm.selectedRows,"searchPlaceholder":_vm.searchPlaceholder,"indexSearch":_vm.indexSearch},on:{"onFilterTextBoxChanged":_vm.onFilterTextBoxChanged,"clickAction":_vm.clickAction}}),_c('sync-grid',{key:_vm.gridKey,ref:"overviewgrid",staticClass:"col-12",staticStyle:{"padding":"0px!important","max-height":"calc(100% - 62px) !important"},attrs:{"id":"overviewgrid","uid":_vm.uid,"commandClick":_vm.commandClick,"gridData":_vm.userParametersListByUser,"name":_vm.name,"headerData":_vm.headerData}}),_c('b-modal',{attrs:{"id":"modal-edit-add-parameter","cancel-title":"Annuler","ok-title":"Enregistrer","centered":"","title":_vm.selectedParameter && _vm.selectedParameter.id ? 'Modifier : '+ _vm.selectedParameter.label : 'Ajouter un paramètre',"size":"lg"},on:{"ok":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',[_c('validation-observer',{ref:"formUserParameter"},[_c('form',{staticClass:"d-flex flex-column",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"key"}},[_vm._v("Clé (unique)")]),_c('b-form-input',{staticClass:"e-input",attrs:{"disabled":_vm.selectedParameter.id > 0,"type":"text","id":"key","required":""},model:{value:(_vm.selectedParameter.key),callback:function ($$v) {_vm.$set(_vm.selectedParameter, "key", $$v)},expression:"selectedParameter.key"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"Label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-1",attrs:{"for":"label"}},[_vm._v("Libellé")]),_c('b-form-input',{staticClass:"e-input",attrs:{"type":"text","id":"label","required":""},model:{value:(_vm.selectedParameter.label),callback:function ($$v) {_vm.$set(_vm.selectedParameter, "label", $$v)},expression:"selectedParameter.label"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mt-1",attrs:{"for":"label"}},[_vm._v("Valeur")]),_c('b-form-textarea',{staticClass:"code-editor",attrs:{"id":"jsonInput","rows":"25","placeholder":"Entrez le JSON"},model:{value:(_vm.selectedParameterJson),callback:function ($$v) {_vm.selectedParameterJson=$$v},expression:"selectedParameterJson"}})]}}])})],1)])],1)]),_c('b-modal',{attrs:{"id":"modal-duplicate-parameter","cancel-title":"Annuler","ok-title":"Appliquer","centered":"","title":"Dupliquer le paramètre","size":"sm"},on:{"ok":function($event){$event.preventDefault();return _vm.submitDuplicationForm()}}},[_c('div',[_c('validation-observer',{ref:"formDuplication"},[_c('form',{staticClass:"d-flex flex-column",on:{"submit":function($event){$event.preventDefault();return _vm.submitDuplicationForm.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex flex-column w100 align-items-center justify-content-center mx-2"},[_c('div',{staticClass:"d-flex flex-column align-items-center w-100 mb-2 mt-2"},[_c('h4',{staticClass:"text-white bg-primary p-1"},[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName.toUpperCase()))])]),_c('div',{staticClass:"d-flex mb-2 align-items-center w-100 flex-column"},[_c('span',{staticClass:"text-center mb-1 text-primary font-weight-bold"},[_vm._v("Dupliquer le paramètre"),_c('br'),_vm._v("\""+_vm._s(_vm.selectedParameter ? _vm.selectedParameter.label : "")+"\"")]),_c('span',{staticClass:"material-icons-outlined text-primary"},[_vm._v("south ")])]),_c('validation-provider',{staticClass:"d-flex flex-column w-100 mb-2",attrs:{"name":"Receivers","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ejs-multiselect',{attrs:{"id":"receivers","closePopupOnSelect":false,"placeholder":"Selectionnez un utilisateur","popupHeight":"300px","sortOrder":"None","dataSource":_vm.usersListFormatted,"fields":{ text: 'displayLabel', value: 'id' }},model:{value:(_vm.duplicateReceivers),callback:function ($$v) {_vm.duplicateReceivers=$$v},expression:"duplicateReceivers"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }