<template lang="pug">
    .p-0
      .content-loader-center.m-0.h-100(v-if='isLoadingUser')
        .text-center.flex-center
          .loading-bg-inner
            .loader
              .outer
              .middle
              .inner
          .mt-5
            br
            br
            br
            | Chargement des détails de l'utilisateur...
      div.details-container(v-else)
         div.d-flex.content-scrollable-sticky.h-100.justify-content-start.w-100
            #nav-sticky-vertical.cell-left.text-center.h-100(ref="navStickyVertical" style='background: #EEF1F6; width: 240px; min-width: 240px; max-width: 240px;')
              .close-btn.d-flex.justify-content-start.pt-1.pr-1(style="padding-left: 0.8rem")
                span.material-icons-outlined(@click="closeMenuSidebar" style="cursor: pointer;")
                  | chevron_left
              .head.p-0
                .d-flex.justify-content-between.px-1
                  div
                    h3.text-nowrap Utilisateur: 
                .d-flex.justify-content-between.pb-1.px-1
                  .d-flex
                    span.text-nowrap(style="white-space: break-spaces;") {{civilityTranslate(user.civility)}} {{user.firstName}} {{user.lastName ? user.lastName.toUpperCase() : ''}}
                  .end
              ul
                li.title
                  vs-divider(color="#cccccc" class="m-0 mt-1 mb-50" position="left")
                    span.text-primary Dossier
                li
                  a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('user')" :class="tabActive == 'user' ? 'current' : ''")
                    span.material-icons-outlined.text-primary
                      | assignment
                    span.pl-1 Informations
                li
                  a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('userParameters')" :class="tabActive == 'userParameters' ? 'current' : ''")
                    span.material-icons-outlined.text-primary
                      | settings
                    span.pl-1 Paramètres
            #content-wrapper.d-flex.flex-column.h-100(style="width: calc(100% - 240px)" ref="contentWrapper")
              .h-100.w-100
                UserDescription(v-if="tabActive=='user'" :userId='user.id')
                UserParameters(v-if="tabActive=='userParameters'" :userId='user.id')
    </template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  BTable,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import GedViewer from "@/components/ged/GedViewer";
import { civilityTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

import UserDescription from "@/views/directory/user/UserDescription";
import UserParameters from "@/views/directory/user/UserParameters";

import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  components: {
    BTable,
    BFormGroup,
    BCardBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    VBTooltip,

    UserDescription,
    UserParameters,
  },
  props: {
    id: {
      default: null,
    },
  },
  watch: {
    async $route(to, from) {
      this.$destroy();
    },
  },
  async mounted() {
    if (this.id !== "" && this.id !== 0 && this.user.id !== this.id) {
      await this.getUserById({ userId: this.id });
    }

    await this.getUsers({});
  },
  data() {
    return {
      tabActive: "user",
    };
  },
  computed: {
    ...mapGetters(["isLoadingUser"]),
    user: {
      get() {
        return this.$store.getters.user;
      },
      set(value) {
        return this.$store.commit("SET_USER", value);
      },
    },
  },
  methods: {
    civilityTranslate,
    ...mapActions(["getUserById", "getUsers"]),
    formatDate,
    activeTab(tab) {
      this.tabActive = tab;
    },
    closeMenuSidebar() {
      this.$refs.navStickyVertical.classList.toggle("sidebar-closed");
      this.$refs.contentWrapper.classList.toggle("sidebar-is-closed");
    },
  },
};
</script>

<style lang="scss">
.con-vs-tabs .con-slot-tabs {
  height: 100%;
  .vs-tabs--content {
    padding: 0px;
    height: 100%;
    > div {
      padding: 0px;
      height: 100%;
    }
  }
}

.sidebar-closed,
.sidebar-closed .title span {
  transition-delay: 0.5s;
  transition: all 0.5s ease-in-out;
}

.sidebar-closed .title span {
  font-size: 0;
}
.sidebar-closed .title .vs-divider {
  margin: 0 !important;
}

.sidebar-closed .close-btn span {
  transform: rotate(180deg);
}

.sidebar-closed .head,
.sidebar-closed .title {
  animation: fadeOut 0.5s ease-in-out forwards;
}

.sidebar-closed {
  width: 52px !important;
  min-width: 52px !important;
  max-width: 52px !important;
}

#content-wrapper {
  transition: all 0.5s ease-in-out;
}

#content-wrapper.sidebar-is-closed {
  transition: all 0.5s ease-in-out;
  width: calc(100% - 52px) !important;
}

#nav-sticky-vertical,
.title span,
.head,
.title,
.title .vs-divider {
  transition: all 0.5s ease-in-out;
}

@keyframes fadeOut {
  from {
    max-height: 100%;
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
  to {
    max-height: 0%;
    width: 0%;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
