<template lang="pug">
div(class="d-flex flex-column h-100 py-0")
  HeaderGrid(title="Paramètres" :uid="uid" @onFilterTextBoxChanged="onFilterTextBoxChanged" @clickAction="clickAction" :addButton="addButton" :searchButton="searchButton" :selectedRows="selectedRows" :searchPlaceholder="searchPlaceholder" :indexSearch="indexSearch")
  sync-grid#overviewgrid(ref="overviewgrid" :uid="uid" :key="gridKey" :commandClick='commandClick' class='col-12' style="padding: 0px!important; max-height: calc(100% - 62px) !important" :gridData="userParametersListByUser" :name="name" :headerData="headerData")
  b-modal(id="modal-edit-add-parameter" cancel-title="Annuler" ok-title="Enregistrer" centered @ok.prevent="submitForm" :title="selectedParameter && selectedParameter.id ? 'Modifier : '+ selectedParameter.label : 'Ajouter un paramètre'" size="lg")
    div
      validation-observer(ref='formUserParameter')
        form.d-flex.flex-column(@submit.prevent="submitForm")
          validation-provider(#default='{ errors }' name='Key' rules='required')
            label(for="key") Clé (unique)
            b-form-input(:disabled="selectedParameter.id > 0" class="e-input" type="text" id="key" v-model="selectedParameter.key" required)
            small.text-danger {{ errors[0] }}
          validation-provider(#default='{ errors }' name='Label' rules='required')
            label.mt-1(for="label") Libellé
            b-form-input(class="e-input" type="text" id="label" v-model="selectedParameter.label" required)
            small.text-danger {{ errors[0] }}
          validation-provider(#default='{ errors }' name='Value')
            label.mt-1(for="label") Valeur
            b-form-textarea.code-editor(id="jsonInput" v-model="selectedParameterJson" rows="25" placeholder="Entrez le JSON")
  b-modal(id="modal-duplicate-parameter" cancel-title="Annuler" ok-title="Appliquer" centered @ok.prevent="submitDuplicationForm()" title="Dupliquer le paramètre" size="sm")
    div
      validation-observer(ref='formDuplication')
        form.d-flex.flex-column(@submit.prevent="submitDuplicationForm")
          .d-flex.flex-column.w100.align-items-center.justify-content-center.mx-2
            .d-flex.flex-column.align-items-center.w-100.mb-2.mt-2
              h4.text-white.bg-primary.p-1 {{  user.firstName }} {{ user.lastName.toUpperCase() }}
            .d-flex.mb-2.align-items-center.w-100.flex-column
              span.text-center.mb-1.text-primary.font-weight-bold Dupliquer le paramètre</br>"{{ selectedParameter ? selectedParameter.label : "" }}"
              span.material-icons-outlined.text-primary
                | south 
            validation-provider.d-flex.flex-column.w-100.mb-2(#default='{ errors }' name='Receivers' rules='required')
              ejs-multiselect(id="receivers" :closePopupOnSelect="false" placeholder='Selectionnez un utilisateur' popupHeight='300px' sortOrder="None" :dataSource='usersListFormatted' :fields="{ text: 'displayLabel', value: 'id' }" v-model="duplicateReceivers")
              small.text-danger {{ errors[0] }}

</template>
<script>
import { debounce } from "lodash";
import Autocomplete from "vuejs-auto-complete";
import vSelect from "vue-select";
import { ECivility } from "@/types/api-orisis/enums/enums";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { civilityTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import SyncGrid from "@/components/global/grid/Grid.vue";
import Vue from "vue";
import {
  GridPlugin,
  Page,
  Toolbar,
  Edit,
  CommandColumn,
} from "@syncfusion/ej2-vue-grids";
import { closest } from "@syncfusion/ej2-base";
Vue.use(GridPlugin);

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  props: {
    userId: {
      default: null,
      required: true,
    },
  },
  data() {
    return {
      indexSearch: 0,
      duplicateReceivers: [],
      isOpenSidebarAffair: false,
      isOpenSidebarLease: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "userParameters",
      uid: "userParameters",
      archive: false,
      headerData: [],
      tabActive: "all",
      selectedRows: [],
      gridKey: 1,
      selectedParameter: null,
      selectedParameterJson: null,
      addButton: true,
      searchButton: true,
      archiveMode: true,
      searchPlaceholder: "Rechercher un paramètre",
      editSettings: { allowEditing: true, allowDeleting: true },
      commands: [
        {
          type: "copyValue",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-copy e-icons",
          },
        },
        {
          type: "duplicateParameter",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-replace e-icons",
          },
        },
        {
          type: "editValue",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-edit e-icons",
          },
        },
        {
          type: "deleteParameter",
          buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
        },
      ],
    };
  },
  provide: {
    grid: [Page, Edit, Toolbar, CommandColumn],
  },
  async created() {
    this.getUserParametersByUserId({
      userId: this.userId,
      isConnectedUser: false,
    }).then(() => {
      this.indexSearch++;
    });
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 15,
        maxWidth: 15,
        visible: true,
      },
      {
        type: "string",
        field: "key",
        headerText: "Clé",
        width: 60,
        maxWidth: 60,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        unavailableExport: true,
        allowGrouping: false,
      },
      {
        type: "string",
        field: "label",
        headerText: "Libellé",
        width: 150,
        maxWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        allowGrouping: false,
        showInColumnChooser: true,
        visible: true,
        filter: { type: "Menu", operator: "contains" },
      },
      {
        type: "string",
        field: "value",
        headerText: "Valeur",
        width: 150,
        maxWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        allowGrouping: false,
        filter: { type: "Menu", operator: "contains" },
      },
      {
        type: "commands",
        commands: this.commands,
        headerText: "Actions",
        width: 120,
      },
    ];
  },
  provide: {
    grid: [CommandColumn],
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    civilityTranslate,
    ...mapActions([
      "getUserParametersByUserId",
      "createOrUpdateUserParameter",
      "deleteUserParameter",
    ]),
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    clickAction() {
      this.selectedParameter = {
        key: "",
        label: "",
        value: "",
      };
      this.selectedParameterJson = "";
      this.editValue(this.selectedParameter);
    },
    commandClick(args) {
      this.selectedParameter = args.rowData;
      if (args.commandColumn.type === "copyValue") {
        this.copyValue(args.rowData);
      } else if (args.commandColumn.type === "duplicateParameter") {
        this.duplicateParameter(args.rowData);
      } else if (args.commandColumn.type === "editValue") {
        this.editValue(args.rowData);
      } else if (args.commandColumn.type === "deleteParameter") {
        this.deleteParameter(args.rowData);
      }
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    copyValue(rowData) {
      navigator.clipboard.writeText(rowData.value);
      this.$bvToast.toast("Paramètre copié.", {
        title: "Succès",
        variant: "success",
        solid: true,
      });
    },
    deleteParameter(rowData) {
      this.$bvModal
        .msgBoxConfirm("Voulez-vous vraiment supprimer ce paramètre ?", {
          title: "Suppression",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteUserParameter({ ids: [rowData.id] });
          }
        })
        .catch(() => {
          // An error occurred
        });
    },
    duplicateParameter(rowData) {
      this.duplicateReceivers = [];
      this.$bvModal.show("modal-duplicate-parameter");
    },
    submitDuplicationForm() {
      this.$refs.formDuplication.validate().then((success) => {
        if (success) {
          // Form is valid, proceed with your logic
          this.duplicateParameterToUsers();
        }
      });
    },
    duplicateParameterToUsers() {
      this.$bvModal.hide("modal-duplicate-parameter");
      this.duplicateReceivers.forEach((receiver) => {
        this.createOrUpdateUserParameter({
          label: this.selectedParameter.label,
          key: this.selectedParameter.key,
          value: this.selectedParameter.value,
          userId: receiver,
        })
          .then(() => {
            this.$bvToast.toast("Paramètre dupliqué.", {
              title: "Succès",
              variant: "success",
              solid: true,
            });
          })
          .catch(() => {
            this.$bvToast.toast("Erreur lors de la duplication du paramètre.", {
              title: "Erreur",
              variant: "danger",
              solid: true,
            });
          });
      });
    },
    editValue(rowData) {
      const originalJson = rowData.value;
      let isValidJson = false;
      try {
        JSON.parse(originalJson);
        isValidJson = true;
      } catch (error) {
        isValidJson = false;
      }

      if (isValidJson) {
        this.selectedParameterJson = JSON.stringify(
          JSON.parse(originalJson),
          null,
          2
        );
      } else {
        this.selectedParameterJson = originalJson;
      }
      this.$bvModal.show("modal-edit-add-parameter");
    },
    submitForm() {
      this.$refs.formUserParameter.validate().then((success) => {
        if (success) {
          // Form is valid, proceed with your logic
          this.EditAddUserParameter(this.selectedParameterJson);
        }
      });
    },
    EditAddUserParameter(json) {
      const messageVariant =
        this.selectedParameter && this.selectedParameter.id
          ? "mis à jour" + this.selectedParameter.label
          : "créé";
      this.createOrUpdateUserParameter({
        label: this.selectedParameter.label,
        key: this.selectedParameter.key,
        value: json,
        userId: this.userId,
      })
        .then(() => {
          this.$bvToast.toast(`Paramètre ${messageVariant}.`, {
            title: "Succès",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("modal-edit-add-parameter");
        })
        .catch(() => {
          this.$bvToast.toast("Une erreur est survenue.", {
            title: "Erreur",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
  computed: {
    ...mapGetters(["user", "userParametersListByUser", "usersList"]),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    usersListFormatted() {
      return this.$store.getters["usersList"]
        .filter((el) => el.id !== this.userId)
        .map((el) => {
          return { ...el, displayLabel: el.firstName + " " + el.lastName };
        });
    },
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
    SyncGrid,
    HeaderGrid,
  },
  directives: {
    Ripple,
  },
};
</script>
<style lang="scss">
.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }

  .code-editor {
    font-family: monospace;
    background-color: rgb(48, 48, 48);
    color: whitesmoke;
    border-radius: 0.5rem;
    padding: 0.5rem;
  }
}
</style>
